.react-datepicker__input-container input {
    font-size: 16px;
    border: none;
    font-family: SourceSansPro-Regular;
    color: #000000;
    width: 100%;
}

.react-datepicker-wrapper {
    flex: 1;
}
